/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { PropsWithChildren, ReactNode } from 'react';
import tw from 'twin.macro';
import { appMaxWidth } from '../constant';

const cssCard = css`
  ${tw`mt-6`}
  max-width: ${appMaxWidth};
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  box-sizing: border-box;
`;

export function RVCard({
  children,
  header,
  ...props
}: PropsWithChildren<{ header?: ReactNode }>) {
  return (
    <div css={cssCard} {...props}>
      {header}
      {children}
    </div>
  );
}
