/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { H } from '@take-two-t2gp/d2c-ui-library';
import tw from 'twin.macro';

const errorContainer = css`
  ${tw`flex flex-col items-center text-center gap-2 rounded p-24`}
  h2 {
    ${tw`type-headline-m mx-0 my-2`}
  }

  p {
    ${tw`type-body-m m-0`}
  }
`;

const cssRefreshButton = css`
  ${tw`type-body-s-bold px-4 mt-2`}
  height: 2rem;
  backdrop-filter: blur(2px);
  text-transform: uppercase;
  border-radius: 4px;
`;

export function RVError({
  title = 'Something went wrong',
  description = '',
  onRefresh,
  ...props
}: {
  title?: string;
  description?: string;
  onRefresh?: () => void;
}) {
  return (
    <div css={errorContainer} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="120"
        height="81"
        fill="none">
        <rect
          width="116"
          height="77"
          x="2"
          y="2"
          stroke="#000"
          strokeWidth="4"
          rx="14"
        />
        <path
          stroke="#000"
          strokeLinecap="round"
          strokeWidth="4"
          d="M50 64.75h20M23.343 43.265l8.485 8.485M31.828 43.093l-8.485 8.485M90.343 43.265l8.485 8.485M98.828 43.093l-8.485 8.485"
        />
      </svg>

      <H>{title}</H>
      <p>{description}</p>

      {onRefresh && (
        <button
          aria-label="refresh"
          type="button"
          onClick={onRefresh}
          css={cssRefreshButton}>
          Refresh
        </button>
      )}
    </div>
  );
}
