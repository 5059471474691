/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { IconRight } from '@take-two-t2gp/d2c-ui-library';
import { BaseSyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';
import { RVDetail } from '../components/RVDetail';
import { ROUTES } from '../constant';
import { useReportDetailFetch } from '../hooks/useReportDetailFetch';

const cssHeader = css`
  ${tw`flex items-center px-12 py-2 gap-2 bg-white my-2`}

  >svg {
    margin-top: 2px;
  }
`;

export function Detail() {
  const navigate = useNavigate();
  const reportDetail = useReportDetailFetch();

  const onClick = (event: BaseSyntheticEvent) => {
    event.preventDefault();
    navigate(ROUTES.HOME);
  };

  return (
    <>
      <section css={cssHeader}>
        {
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a onClick={onClick} href="">
            Error Report Viewer
          </a>
        }
        <IconRight />
        {reportDetail?.uuid || 'undefined'}
      </section>

      <RVDetail reportItem={reportDetail} />
    </>
  );
}
