import { useCallback, useEffect } from 'react';
import { useReportData, useSetReportData } from '../context/ReportDataContext';
import { useReportFetchParams } from '../context/ReportFetchParamsContext';

export function useReportItemsFetch() {
  const reportFetchParams = useReportFetchParams();
  const setReportData = useSetReportData();
  const reportData = useReportData();

  const fetchReportItems = useCallback(async () => {
    setReportData({ ...reportData, loading: true, error: '' });
    const page = reportFetchParams.page - 1;

    try {
      const response = await fetch(
        `/internal/api/search?${new URLSearchParams({
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ...(reportFetchParams as any),
          from: page * reportFetchParams.size,
          page,
        }).toString()}`
      );

      if (response.ok) {
        const result = (await response.json()) ?? {};
        setReportData({
          data: { ...result, aggregation: result.aggs['by type'] },
          loading: false,
          error: '',
        });
      } else {
        setReportData({
          ...reportData,
          loading: true,
          error: `Failed to fetch new report data ${response.statusText}`,
        });
      }
    } catch (error) {
      setReportData({
        ...reportData,
        loading: false,
        error: `Failed to fetch new report data`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportFetchParams, setReportData]);

  useEffect(() => {
    fetchReportItems();
  }, [fetchReportItems]);
}
