export enum ROUTES {
  HOME = '/',
  DETAIL = '/detail/:id',
}

export const REPORT_ERROR_TYPE = {
  gameLoading: 'Game Loading',
  installationUpdates: 'Installation/Updates',
  clientCrashes: 'Client Crashes',
  NO_TYPE_V1: 'Auto Report',
  purchasesTransactions: 'Transactions',
  modsManagement: 'Mods Management',
  launcherCrashes: 'Launcher Crashes',
  other: 'Other',
};

export const DEFAULT_FETCH_PARAMS = {
  page: 1,
  size: 20,
  from: 0,
  sort: 'desc',
  reportUUID: '',
  rangeStart: undefined,
  rangeEnd: undefined,
};

export const isPDLogs = window.location.origin.includes('-pd');

export const PLATFORMS = {
  Steam: 'Steam',
  Epic: 'Epic',
};

export const METADATA_LOADING_ERROR_MESSAGE =
  'Failed to load Metadata. Please try again later.';

export const appMaxWidth = '120rem';
