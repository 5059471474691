/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { IconUp, LoadingSpinner } from '@take-two-t2gp/d2c-ui-library';
import { useMemo } from 'react';
import ReactJson from 'react-json-view';
import tw from 'twin.macro';
import { REPORT_ERROR_TYPE, appMaxWidth } from '../constant';
import { useGetBundleHandler } from '../hooks/useGetBundleHandler';
import { useReportMetadata } from '../hooks/useReportMetadata';
import { getPlatformLabel } from '../lib';
import { ReportItem } from '../types';
import { RVCard } from './RVCard';
import { RVError } from './RVError';

const cssReportDetail = css`
  ${tw`px-12 flex flex-col items-center gap-4`}

  > h1 {
    ${tw`type-headline-xl mb-0 w-full`}
    max-width: ${appMaxWidth};
  }
`;

const cssSection = css`
  border: solid 1px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  min-height: 15rem;
  background: white;
  width: 100%;

  > div {
    ${tw`p-4 flex items-center justify-center h-full`}
  }
`;

const cssSectionHeader = css`
  ${tw`type-headline-m px-4 m-0 py-4 flex items-center gap-2`}
  background: rgba(245, 245, 245, 1);
`;

const cssInfoContainer = css`
  ${tw`w-full flex items-center`}

  > span {
    display: inline-block;
  }
`;

const cssLabel = css`
  ${tw`type-title uppercase`}
  min-width: 12rem;
`;

const cssReportItemInfo = tw`flex flex-col gap-4`;

const cssGetBundleButton = css`
  ${tw`type-body-s-bold ml-auto px-4`}
  height: 2rem;
  background: #394dff;
  backdrop-filter: blur(2px);
  color: white;
  text-transform: uppercase;
  border: none;
  border-radius: 4px;
`;

const cssValue = tw`type-body-m`;

const cssMetadataError = css`
  width: 100%;
  background: #f5f5f5;
`;

export function RVDetail({ reportItem }: { reportItem?: ReportItem }) {
  const onGetBundle = useGetBundleHandler();
  const { reportMetadata, metadataLoading, metadataError, onRefresh } =
    useReportMetadata(reportItem);

  const metaDataErrorElement = useMemo(
    () =>
      metadataError ? (
        <RVError
          description="Metadata failed to load. Please try again later."
          css={cssMetadataError}
          onRefresh={onRefresh}
        />
      ) : (
        <RVError
          title="No Metadata Available"
          description="This report does not have metadata."
          css={cssMetadataError}
        />
      ),
    [metadataError, onRefresh]
  );

  const metaDataSection = useMemo(
    () =>
      reportMetadata ? (
        <ReactJson
          style={{
            width: '100%',
            padding: '0.5rem',
            height: '30rem',
            overflow: 'auto',
            backgroundColor: '#f5f5f5',
            border: ' solid 1px rgba(0, 0, 0, 0.2)',
            borderRadius: '4px',
          }}
          displayDataTypes={false}
          iconStyle="triangle"
          src={reportMetadata as object}
        />
      ) : (
        metaDataErrorElement
      ),
    [reportMetadata, metaDataErrorElement]
  );

  if (!reportItem) return null;
  const { uuid, keys } = reportItem;

  return (
    <div css={cssReportDetail}>
      <h1>{uuid || 'undefined'}</h1>

      <RVCard
        css={cssSection}
        header={
          <h1 css={cssSectionHeader}>
            <IconUp />
            Report Details
            <button
              aria-label="Get Bundle"
              title="Get Bundle"
              type="button"
              onClick={onGetBundle(keys)}
              disabled={!keys.bundle}
              css={cssGetBundleButton}>
              Get Bundle
            </button>
          </h1>
        }>
        <div css={cssReportItemInfo}>
          <div css={cssInfoContainer}>
            <span css={cssLabel}>Error Type</span>
            <span css={cssValue}>
              {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (REPORT_ERROR_TYPE as any)[reportItem.type] || reportItem.type
              }
            </span>
          </div>
          <div css={cssInfoContainer}>
            <span css={cssLabel}>Launcher Version</span>
            <span css={cssValue}>{reportMetadata?.launcherVer}</span>
          </div>
          <div css={cssInfoContainer}>
            <span css={cssLabel}>Timestamp</span>
            <span css={cssValue}>
              {new Date(reportItem.timestamp).toLocaleString()}
            </span>
          </div>
          <div css={cssInfoContainer}>
            <span css={cssLabel}>platform</span>
            <span css={cssValue}>{getPlatformLabel(reportItem.platform)}</span>
          </div>
          <div css={cssInfoContainer}>
            <span css={cssLabel}>Session Id</span>
            <span css={cssValue}> {reportItem.id}</span>
          </div>
          <div css={cssInfoContainer}>
            <span css={cssLabel}>Description</span>
            <span css={cssValue}>{reportMetadata?.description}</span>
          </div>
        </div>
      </RVCard>

      <RVCard
        css={cssSection}
        header={
          <h1 css={cssSectionHeader}>
            <IconUp />
            Metadata
          </h1>
        }>
        <div>
          {metadataLoading ? (
            <LoadingSpinner size={40} color="black" />
          ) : (
            metaDataSection
          )}
        </div>
      </RVCard>
    </div>
  );
}
