/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  cssContainerPadding,
  IconCloseCircleOutline,
  IconLeft,
  IconRight,
  IconSearch,
  IconUp,
} from '@take-two-t2gp/d2c-ui-library';
import { useCallback, useMemo } from 'react';
import tw from 'twin.macro';
import { RVAggregation } from '../components/RVAggregation';
import { RVCard } from '../components/RVCard';
import { RVContentTable } from '../components/RVContentTable';
import { RVFilter } from '../components/RVFilter';
import { appMaxWidth, isPDLogs } from '../constant';
import { useReportData } from '../context/ReportDataContext';
import {
  useReportFetchParams,
  useSetReportFetchParams,
} from '../context/ReportFetchParamsContext';
import { useReportItemsFetch } from '../hooks/useReportItemsFetch';
import { getErrorType } from '../lib';

const cssHome = css`
  > img {
    width: 100%;
    max-height: 300px;
    object-fit: fill;
  }
`;
const cssContent = css`
  ${cssContainerPadding}
  ${tw`flex flex-col items-center`}
`;

const cssPageNav = tw`flex justify-center gap-2 items-center mb-4`;
const cssPageButton = tw`border-none bg-white`;

const cssTable = tw`w-full mb-8`;
const cssTableHeader = css`
  ${tw`type-headline-m px-4 m-0 py-4 flex items-center gap-2`}
  background: rgba(245, 245, 245, 1);
`;

const cssUUIDFitler = css`
  ${tw`flex flex-row items-center gap-2 px-8 py-4`}
  border-bottom:solid 1px rgba(0, 0, 0, 0.2);

  & > input {
    ${tw`type-body-s`}
    width: 100%;
    border: none;
  }

  > svg {
    width: auto;
    height: 1.2rem;
  }
`;

const cssFilterItems = css`
  ${tw`w-full flex type-body-s mt-6`}
  max-width: ${appMaxWidth}
`;

const cssFilterItem = css`
  ${tw`px-2 py-1 flex items-center gap-1`}
  background: rgba(57,77,255,0.2);
  border-radius: 4px;

  > button {
    ${tw`flex type-body-s p-0 border-none bg-transparent`}

    > svg {
      width: 1.25rem;
      height: 1.75rem;
    }
  }
`;

const cssAggregationCard = tw`w-full`;

const cssFilterCard = css`
  ${tw`flex flex-col justify-center flex-wrap w-full p-6`}
`;

export function Home() {
  useReportItemsFetch();
  const reportFetchParams = useReportFetchParams();
  const setReportFetchParams = useSetReportFetchParams();

  const { data: reportData } = useReportData();

  const totalPage = useMemo(
    () => Math.ceil((reportData?.total.value || 0) / reportFetchParams.size),
    [reportData?.total.value, reportFetchParams.size]
  );

  const onInputChange =
    (key: string, type = 'string') =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any) => {
      setReportFetchParams({
        ...reportFetchParams,
        page: 1,
        [key]:
          type === 'number'
            ? parseInt(event.target.value, 10)
            : event.target.value,
      });
    };

  const onPrevPage = useCallback(() => {
    if (reportFetchParams.page > 0) {
      setReportFetchParams({
        ...reportFetchParams,
        page: reportFetchParams.page - 1,
      });
    }
  }, [reportFetchParams, setReportFetchParams]);

  const onNextPage = useCallback(() => {
    if (reportFetchParams.page < totalPage)
      setReportFetchParams({
        ...reportFetchParams,
        page: reportFetchParams.page + 1,
      });
  }, [reportFetchParams, setReportFetchParams, totalPage]);

  const onErrorTypeClear = () => {
    setReportFetchParams({
      ...reportFetchParams,
      type: '',
      page: 1,
    });
  };

  return (
    <div css={cssHome}>
      <img src="/HeroImg.jpg" alt="heroImg" />
      <section css={cssContent}>
        <RVCard css={cssAggregationCard}>
          <RVAggregation />
        </RVCard>
        <RVCard css={cssFilterCard}>
          <RVFilter />
        </RVCard>
        {reportFetchParams.type && (
          <div css={cssFilterItems}>
            <span css={cssFilterItem}>
              {getErrorType(reportFetchParams.type)}
              <button
                aria-label={`Clear ${reportFetchParams.type}`}
                type="button"
                onClick={onErrorTypeClear}>
                <IconCloseCircleOutline />
              </button>
            </span>
          </div>
        )}
        <RVCard
          css={cssTable}
          header={
            <h1 css={cssTableHeader}>
              <IconUp /> {isPDLogs ? 'PD' : '2k'} Logs
            </h1>
          }>
          <div css={cssUUIDFitler}>
            <IconSearch />
            <input
              placeholder="Search Reference ID"
              size={40}
              value={reportFetchParams.reportUUID}
              onChange={onInputChange('reportUUID')}
            />
          </div>

          <RVContentTable />
          <div css={cssPageNav}>
            <span>Size:</span>
            <input
              type="number"
              min="1"
              max="10000"
              value={reportFetchParams.size}
              onChange={onInputChange('size', 'number')}
            />

            <button
              type="button"
              onClick={onPrevPage}
              css={cssPageButton}
              disabled={reportFetchParams.page <= 1}>
              <IconLeft />
            </button>
            <span>Page ({totalPage}) </span>
            <input
              type="number"
              min="1"
              max={totalPage}
              value={reportFetchParams.page}
              onChange={onInputChange('page', 'number')}
            />
            <button
              type="button"
              onClick={onNextPage}
              css={cssPageButton}
              disabled={reportFetchParams.page >= totalPage}>
              <IconRight />
            </button>
          </div>
        </RVCard>
      </section>
    </div>
  );
}
