import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { DEFAULT_FETCH_PARAMS } from '../constant';
import { ReportItem } from '../types';

export function useReportDetailFetch() {
  const { state } = useLocation();
  const [reportItem, setReportItem] = useState<ReportItem | undefined>(
    state?.reportItem
  );
  const { id } = useParams();
  useEffect(() => {
    async function fetchReportDetail() {
      if (!state?.reportItem) {
        // fetch log data based on the uuid from the URL
        const response = await fetch(
          `/internal/api/search?${new URLSearchParams({
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ...(DEFAULT_FETCH_PARAMS as any),
            reportId: id || '',
          }).toString()}`
        );

        if (response.ok) {
          const result = (await response.json()) ?? {};
          const item = result?.items?.[0];
          // eslint-disable-next-line no-console
          console.log('fetched detail:', item);

          setReportItem(item);
        } else {
          // eslint-disable-next-line no-console
          console.warn('Encountered error searching', response);
        }
      }
    }

    fetchReportDetail();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return reportItem;
}
