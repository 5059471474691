import { useCallback, useEffect, useState } from 'react';
import { fetchMetaData } from '../lib';
import { Metadata, ReportItem } from '../types';

export function useReportMetadata(reportItem?: ReportItem) {
  const [reportMetadata, setReportMetadata] = useState<Metadata | undefined>();
  const [metadataLoading, setMetadataLoading] = useState(false);
  const [metadataError, setMetadataError] = useState(false);

  const getMetadata = useCallback(async () => {
    if (reportItem) {
      try {
        setMetadataLoading(true);
        setMetadataError(false);
        const jsonData = await fetchMetaData(reportItem.keys);
        setReportMetadata(jsonData);
      } catch (error) {
        setMetadataError(true);
        // eslint-disable-next-line no-console
        console.log('metadata error', error);
      } finally {
        setMetadataLoading(false);
      }
    }
  }, [reportItem]);

  useEffect(() => {
    getMetadata();
  }, [getMetadata]);

  const onRefresh = getMetadata;

  return { reportMetadata, metadataLoading, metadataError, onRefresh };
}
