/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';
import { isPDLogs } from '../constant';
import { AppVersion } from '../types';

const T2GP_LOGO_PATH = '/T2GP_Dark_Refresh-SplitColors.jpg';

const LABEL_LOGO_PATH = isPDLogs
  ? '/assets/pd-logo.svg'
  : '/assets/2k-logo.svg';

const cssAppHeader = css`
  ${tw`py-3 px-12 flex items-center justify-start`};
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2);

  > img {
    object-fit: contain;
  }

  > hr {
    border: none;
    height: 2rem;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.2);
    margin: 0 1rem;
    padding: 0;
  }

  > span {
    ${tw`type-body-m-bold whitespace-nowrap ml-4`}
  }
`;

export function RVHeader({ version }: { version?: AppVersion }) {
  return (
    <header css={cssAppHeader}>
      <img src={T2GP_LOGO_PATH} alt="t2gp-logo" />
      <hr />
      <img src={LABEL_LOGO_PATH} alt="label-logo" />
      <span>Error Reporter Viewer ({version?.version})</span>
    </header>
  );
}
