/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { positionMatchWidth } from '@reach/popover';
import {
  H,
  IconDown,
  IconUp,
  Menu,
  MenuButton,
  MenuPopover,
} from '@take-two-t2gp/d2c-ui-library';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import { Ref, useCallback, useEffect, useRef, useState } from 'react';
import { DateRangePicker } from 'rsuite';
import { DateRange, ValueType } from 'rsuite/esm/DateRangePicker';
import tw from 'twin.macro';
import {
  DEFAULT_FETCH_PARAMS,
  PLATFORMS,
  REPORT_ERROR_TYPE,
} from '../constant';
import {
  useReportFetchParams,
  useSetReportFetchParams,
} from '../context/ReportFetchParamsContext';
import { getErrorType } from '../lib';

const cssFilterContainer = tw`flex justify-between items-center`;
const cssFilterSection = css`
  ${tw`flex flex-col gap-2 flex-grow w-full basis-1/2`}

  [data-reach-menu-button] {
    background: #f5f5f5;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    ${tw`w-full type-body-m flex items-center justify-between px-4 py-3 whitespace-nowrap`}
  }
`;

const cssFitlerBy = tw`mr-4`;
const cssDateRangeFitler = tw`inline-flex flex-row flex-wrap w-full h-full`;
const cssFilterEntry = css`
  ${tw`flex flex-row items-center gap-2 w-full h-full`}

  & > span {
    ${tw`whitespace-nowrap`}
  }

  > *:first-of-type {
    min-width: 5rem;
  }

  min-height: 50px;
`;
const cssFitlerItemsContainer = css`
  > h2 {
    ${tw`type-title`}
  }

  > span {
    ${tw`type-subtext-60`}
  }

  ${tw`mr-8`}
`;
const cssInputContainer = css`
  ${tw`py-2 flex items-center`}

  > label {
    ${tw`type-body-s`};
    margin-inline-start: 0.5rem;
  }
`;

const cssMenuPopover = tw`flex`;
const cssApplyFiltersButton = css`
  ${tw`type-body-s-bold ml-2 px-4`}
  height: 2rem;
  background: #394dff;
  backdrop-filter: blur(2px);
  color: white;
  text-transform: uppercase;
  border: none;
  border-radius: 4px;

  &[disabled] {
    background: #394dff;
    opacity: 0.4;
  }
`;

const cssClearAllButton = css`
  ${tw`type-body-s ml-2 px-4 whitespace-nowrap`}
  height: 2rem;
  background: transparent;
  backdrop-filter: blur(2px);
  color: black;
  text-transform: captalize;
  text-decoration: underline;
  border: none;

  &[disabled] {
    background: transparent;
    opacity: 0.4;
  }
`;

export function RVFilter({ ...props }) {
  const menuButton = useRef<HTMLButtonElement>();
  const reportFetchParams = useReportFetchParams();
  const setReportFetchParams = useSetReportFetchParams();
  const [errorTypeSelected, setErrorTypeSelected] = useState('');

  const [dateRange, setDateRange] = useState<ValueType>([]);

  useEffect(() => {
    setErrorTypeSelected(reportFetchParams?.type || '');
  }, [reportFetchParams.type]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onErrorTypeSelected = useCallback((event: any) => {
    event.stopPropagation();
    setErrorTypeSelected(event.target.value);
  }, []);

  const onApplyFiltersButtonClicked = () => {
    setReportFetchParams({
      ...reportFetchParams,
      type: errorTypeSelected,
      page: 1, // reset page to first page
    });
    menuButton.current?.focus();
  };

  const onClearAllButtonClicked = useCallback(() => {
    setErrorTypeSelected('');
    setDateRange([]);
    setReportFetchParams({
      ...DEFAULT_FETCH_PARAMS,
      reportUUID: reportFetchParams.reportUUID,
    });
  }, [reportFetchParams.reportUUID, setReportFetchParams]);

  const onDateRangeChange = (value: DateRange | null) => {
    if (value) {
      const [startDate, endDate] = value;

      const rangeStartDate = new Date(startDate);
      rangeStartDate.setUTCHours(0, 0, 0);
      const rangeEndDate = new Date(endDate);
      rangeEndDate.setUTCHours(23, 59, 59);

      setReportFetchParams({
        ...reportFetchParams,
        rangeStart: rangeStartDate.toISOString(),
        rangeEnd: rangeEndDate.toISOString(),
        page: 1, // reset page to first page
      });

      setDateRange(value);
    }
  };

  const onDateRangeClear = () => {
    setReportFetchParams({
      ...reportFetchParams,
      rangeStart: undefined,
      rangeEnd: undefined,
      page: 1, // reset page to first page
    });

    setDateRange([]);
  };

  return (
    <div css={cssFilterContainer} {...props}>
      <div css={[cssFilterSection, cssFitlerBy]}>
        <Menu>
          {({ isExpanded }) => (
            <>
              <MenuButton ref={menuButton as Ref<HTMLButtonElement>}>
                Filter by
                {isExpanded ? <IconUp /> : <IconDown />}
              </MenuButton>
              <MenuPopover position={positionMatchWidth}>
                <div css={cssMenuPopover}>
                  <div css={cssFitlerItemsContainer}>
                    <H>Error Type</H>
                    <span>single selection only</span>
                    {Object.keys(REPORT_ERROR_TYPE).map(key => (
                      <div key={key} css={cssInputContainer}>
                        <input
                          type="radio"
                          id={key}
                          name={key}
                          value={key}
                          checked={errorTypeSelected === key}
                          onChange={onErrorTypeSelected}
                        />
                        {
                          // eslint-disable-next-line jsx-a11y/label-has-associated-control
                          <label htmlFor={key}>{getErrorType(key)}</label>
                        }
                      </div>
                    ))}
                  </div>
                  <div css={cssFitlerItemsContainer}>
                    <H>Platform</H>
                    <span>not available</span>
                    {Object.keys(PLATFORMS).map(key => (
                      <div key={key} css={cssInputContainer}>
                        <input type="radio" id={key} name={key} disabled />
                        {
                          // eslint-disable-next-line jsx-a11y/label-has-associated-control
                          <label htmlFor={key} css={tw`opacity-60`}>
                            {key}
                          </label>
                        }
                      </div>
                    ))}
                  </div>
                </div>
                <hr />
                <div css={tw`flex justify-end`}>
                  <button
                    aria-label="Apply Filters"
                    type="button"
                    disabled={!errorTypeSelected}
                    onClick={onApplyFiltersButtonClicked}
                    css={cssApplyFiltersButton}>
                    Apply Filters
                  </button>
                </div>
              </MenuPopover>
            </>
          )}
        </Menu>
      </div>
      <div css={[cssFilterSection, cssDateRangeFitler]}>
        <div css={cssFilterEntry}>
          <DateRangePicker
            size="lg"
            showWeekNumbers={false}
            placeholder="Select date range"
            style={{
              width: '100%',
              zIndex: 0,
            }}
            format="MM/dd/yyyy "
            onChange={onDateRangeChange}
            character=" - "
            value={dateRange as DateRange}
            onClean={onDateRangeClear}
            editable
            locale={{ ok: 'APPLY' }}
            ranges={[
              {
                label: 'Last 7 Days',
                value: [
                  startOfDay(subDays(new Date(), 6)),
                  endOfDay(new Date()),
                ],
              },
              {
                label: 'Last 30 Days',
                value: [
                  startOfDay(subDays(new Date(), 29)),
                  endOfDay(new Date()),
                ],
              },
              {
                label: 'Last 60 Days',
                value: [
                  startOfDay(subDays(new Date(), 59)),
                  endOfDay(new Date()),
                ],
              },
            ]}
          />
        </div>
      </div>
      <button
        aria-label="Clear All"
        type="button"
        onClick={onClearAllButtonClicked}
        css={cssClearAllButton}>
        Clear All
      </button>
    </div>
  );
}
