import { useEffect, useState } from 'react';
import { AppVersion } from '../types';

export function useAppVersion(): [AppVersion | undefined] {
  const [appVersion, setAppVersion] = useState<AppVersion>();
  useEffect(() => {
    async function getVersion() {
      const response = await fetch('/version');
      setAppVersion(await response.json());
    }

    getVersion();
  }, []);

  return [appVersion];
}
