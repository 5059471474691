/** @jsxImportSource @emotion/react */
import { createStateContext } from '@take-two-t2gp/d2c-ui-library';
import { DEFAULT_FETCH_PARAMS } from '../constant';
import { ReportFetchParams } from '../types';

export const {
  Provider: ReportFetchParamsProvider,
  useState: useReportFetchParams,
  useSetState: useSetReportFetchParams,
} = createStateContext<ReportFetchParams>(DEFAULT_FETCH_PARAMS);
