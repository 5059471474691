import { BaseSyntheticEvent } from 'react';
import { fetchDownloadLink } from '../lib';

export function useGetBundleHandler() {
  return (keys: { bucket: string; bundle?: string }) =>
    async (event: BaseSyntheticEvent) => {
      event.preventDefault();
      const fetchUrl = await fetchDownloadLink(keys.bucket, keys.bundle || '');
      if (fetchUrl) {
        // eslint-disable-next-line no-console
        console.log('Bundle URL:', fetchUrl);
        window.open(fetchUrl);
      }
    };
}
