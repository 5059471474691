/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';
import { REPORT_ERROR_TYPE } from '../constant';
import { useReportData } from '../context/ReportDataContext';

const cssAggregationItem = css`
  ${tw`flex flex-col pr-8`}
  min-width: 12rem;

  > span {
    ${tw`whitespace-nowrap`}
  }
`;
const cssAggregationValue = tw`uppercase type-headline-l`;

const cssTitle = tw`uppercase type-title mb-2`;

const cssAggregation = tw`
  flex flex-row flex-wrap gap-4 w-full p-6
`;

export function RVAggregation() {
  const { data: reportData } = useReportData();

  return (
    <div css={cssAggregation}>
      <div css={cssAggregationItem}>
        <span css={cssTitle}>Total logs</span>
        <span css={cssAggregationValue}>
          {reportData?.total.value} ({reportData?.total.relation})
        </span>
      </div>

      {reportData?.aggregation &&
        Object.keys(REPORT_ERROR_TYPE).map(key => (
          <div css={cssAggregationItem} key={key}>
            <span css={cssTitle}>
              {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (REPORT_ERROR_TYPE as any)[key] || key
              }
            </span>
            <span css={cssAggregationValue}>
              {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (reportData?.aggregation as any)[key] || 'n/a'
              }
            </span>
          </div>
        ))}
    </div>
  );
}
