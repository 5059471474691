import { REPORT_ERROR_TYPE } from '../constant';

export async function fetchDownloadLink(bucket: string, key: string) {
  if (!bucket || !key) {
    // eslint-disable-next-line no-console
    console.log('Missing required fields');
    return '';
  }

  const response = await fetch('/internal/api/presigned', {
    body: JSON.stringify({
      bucket,
      key,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });

  if (!response.ok) {
    // eslint-disable-next-line no-console
    console.log('Error fetching:', response);
    return '';
  }

  const result = await response.json();
  // eslint-disable-next-line no-console
  console.log('Presigned response:', result);

  return result.url;
}

export const fetchMetaData = async (keys: {
  bucket: string;
  metadata?: string;
}) => {
  const fetchUrl = await fetchDownloadLink(keys.bucket, keys.metadata || '');

  if (fetchUrl) {
    // eslint-disable-next-line no-console
    console.log('Metadata URL:', fetchUrl);

    const result = await fetch(fetchUrl).then(response => response.json());

    return result;
  }

  return undefined;
};

export const getErrorType = (type: string) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (REPORT_ERROR_TYPE as any)[type] || type;

export const getPlatformLabel = (platform: string) => {
  if (platform.includes('steam')) return 'Steam';
  if (platform.includes('epic')) return 'Epic';
  if (platform.includes('launcher')) return 'Launcher';

  return platform;
};
