/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  Icon2K,
  IconBundle,
  IconEpic,
  IconMetadata,
  IconPrivateDivision,
  IconSteam,
  Lightbox,
  LoadingSpinner,
  Tooltip,
} from '@take-two-t2gp/d2c-ui-library';
import { BaseSyntheticEvent, forwardRef, useState } from 'react';
import ReactJson from 'react-json-view';
import { Link } from 'react-router-dom';
import tw from 'twin.macro';
import { REPORT_ERROR_TYPE } from '../constant';
import { useReportData } from '../context/ReportDataContext';
import {
  useReportFetchParams,
  useSetReportFetchParams,
} from '../context/ReportFetchParamsContext';
import { useGetBundleHandler } from '../hooks/useGetBundleHandler';
import { fetchMetaData, getPlatformLabel } from '../lib';
import { Metadata } from '../types';
import { RVError } from './RVError';

const cssTitle = tw`uppercase type-title mb-2`;

const cssItemTable = css`
  width: 100%;
  border-spacing: 0;
  padding: 1.5rem;

  th,
  td {
    text-align: left;
    ${tw`px-8 py-4`}
    font-size: 0.875rem;
  }

  thead > tr {
    ${cssTitle}

    >th {
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      white-space: nowrap;
    }
  }

  tbody > tr:nth-of-type(2n) {
    background: #f5f5f5;
    border: none;
  }
`;

const cssLightbox = css`
  ${tw`max-w-lg w-full p-8 pt-12`}
  background: #F5F5F5;

  h1 {
    ${tw`type-headline-l text-center`}
  }

  button {
    border: none;
    background: #f5f5f5;
  }

  pre {
    padding: 1rem;
    background: white;
  }
`;

const cssUUID = css`
  text-decoration: underline;

  &:active {
    color: black;
  }

  &:visited {
    color: black;
  }

  &:hover {
    text-decoration: none;
  }
`;

const cssTimestampButton = css`
  background: transparent;
  color: black;
  border: none;
`;

const cssMetadataError = css`
  background: #ebebeb;
`;

const cssActionContainer = css`
  ${tw`flex gap-2`}

  button {
    ${tw`flex items-center self-end`}
    background: white;
    width: 2rem;
    height: 2rem;
    border: 1px solid #000000;
    border-radius: 4px;

    &:not([disabled]):hover {
      background: buttonface;
    }
  }
`;

const cssMetadataLoading = css`
  background: #ebebeb;
  min-height: 24rem;
  ${tw`flex flex-col items-center justify-center text-center gap-2 rounded p-24`}}

`;

const cssTooltip = css`
  &.__react_component_tooltip {
    background: #e1e1e1;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
    border-radius: 4px;
    padding: 0.5rem;
    text-transform: capitalize;
  }
`;

export const RVContentTable = forwardRef<HTMLTableElement>((props, ref) => {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [reportMetadata, setReportMetadata] = useState<Metadata>();
  const [metadataError, setMetadataError] = useState(false);
  const [selectedUUID, setSelectedUUID] = useState('');
  const [metadataLoading, setMetadataLoading] = useState(false);
  const onGetBundle = useGetBundleHandler();
  const reportFetchParams = useReportFetchParams();
  const setReportFetchParams = useSetReportFetchParams();

  const {
    data: reportData,
    loading: reportDataLoading,
    error: reportFetchingError,
  } = useReportData();

  const onTimeStampButtonClick = () => {
    setReportFetchParams({
      ...reportFetchParams,
      sort: reportFetchParams.sort === 'desc' ? 'asc' : 'desc',
    });
  };

  const onGetMetadata =
    (uuid: string, keys: { bucket: string; metadata?: string }) =>
    async (event: BaseSyntheticEvent) => {
      event.preventDefault();
      setIsLightboxOpen(true);
      setSelectedUUID(uuid);
      try {
        setMetadataLoading(true);
        const jsonData = await fetchMetaData(keys);
        setReportMetadata(jsonData);
      } catch (error) {
        setMetadataError(true);
        // eslint-disable-next-line no-console
        console.log(error);
      } finally {
        setMetadataLoading(false);
      }
    };

  const getPlatform = (platform = 'Missing Platform') => {
    if (platform.includes('steam')) return <IconSteam />;
    if (platform.includes('epic')) return <IconEpic />;
    if (platform.includes('2k_launcher')) return <Icon2K />;
    if (platform.includes('pd_launcher')) return <IconPrivateDivision />;
    if (platform.includes('launcher')) return 'Launcher';

    return platform;
  };

  const onLightboxClose = () => {
    setIsLightboxOpen(false);
    setReportMetadata(undefined);
    setMetadataError(false);
    setSelectedUUID('');
    setMetadataLoading(false);
  };

  return (
    <>
      <table css={cssItemTable} ref={ref} {...props}>
        <thead>
          <tr>
            <th>Reference Id</th>
            <th>Report Type</th>
            <th>
              Timestamp
              <Tooltip id="timesort" label="Timestamp Sort" css={cssTooltip}>
                <button
                  aria-label="Timestamp Sort"
                  type="button"
                  onClick={onTimeStampButtonClick}
                  css={cssTimestampButton}>
                  {reportFetchParams.sort === 'desc' ? (
                    <>&#x25BC;</>
                  ) : (
                    <>&#x25B2;</>
                  )}
                </button>
              </Tooltip>
            </th>
            <th>Platform</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody>
          {reportDataLoading ? (
            <tr>
              <td colSpan={5} style={{ textAlign: 'center' }}>
                <LoadingSpinner size={40} color="black" />
              </td>
            </tr>
          ) : null}
          {reportData?.items.map((reportItem, idx) => {
            const { _docId, id, uuid, type, platform, timestamp, keys } =
              reportItem;

            return (
              <tr key={_docId}>
                <td>
                  {
                    // detail page is using report id instead of UUID
                    // to load a report when state value is missing
                    <Link
                      to={`/detail/${id}`}
                      state={{ reportItem }}
                      css={cssUUID}>
                      {uuid || 'undefined'}
                    </Link>
                  }
                </td>
                <td>
                  {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (REPORT_ERROR_TYPE as any)[type] || type
                  }
                </td>

                <td>
                  {timestamp} ({new Date(timestamp).toLocaleString()})
                </td>
                <td>
                  <Tooltip
                    id={`${platform}-${idx}`}
                    label={getPlatformLabel(platform)}
                    css={cssTooltip}>
                    {getPlatform(platform)}
                  </Tooltip>
                </td>
                <td>
                  <div css={cssActionContainer}>
                    <div
                      css={css`
                        min-width: 2rem;
                      `}>
                      {keys.metadata && (
                        <Tooltip
                          id={`get-metadata-${idx}`}
                          label="Get Metadata"
                          css={cssTooltip}>
                          <button
                            aria-label="Get Metadata"
                            type="button"
                            onClick={onGetMetadata(uuid, keys)}
                            disabled={!keys.metadata}>
                            <IconMetadata />
                          </button>
                        </Tooltip>
                      )}
                    </div>

                    <Tooltip
                      id={`get-bundle-${idx}`}
                      label="Get Bundle"
                      css={cssTooltip}>
                      <button
                        aria-label="Get Bundle"
                        type="button"
                        onClick={onGetBundle(keys)}
                        disabled={!keys.bundle}>
                        <IconBundle />
                      </button>
                    </Tooltip>
                  </div>
                </td>
              </tr>
            );
          })}

          {reportFetchingError && (
            <tr>
              <td colSpan={5} style={{ textAlign: 'center' }}>
                {reportFetchingError}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Lightbox
        labelClose="Close"
        isOpen={isLightboxOpen}
        requestClose={onLightboxClose}
        spaced={false}
        css={cssLightbox}
        centered>
        <>
          <h1>{selectedUUID}</h1>
          {metadataLoading && (
            <div css={cssMetadataLoading}>
              <LoadingSpinner size={40} color="black" />
            </div>
          )}
          {reportMetadata ? (
            <ReactJson
              style={{
                minHeight: '30rem',
                padding: '1rem',
                maxHeight: '30rem',
                overflow: 'auto',
                backgroundColor: 'white',
              }}
              iconStyle="triangle"
              displayDataTypes={false}
              src={reportMetadata as object}
            />
          ) : (
            metadataError && (
              <RVError
                description="Metadata failed to load. Please close this modal and try again."
                css={cssMetadataError}
              />
            )
          )}
        </>
      </Lightbox>
    </>
  );
});
