/** @jsxImportSource @emotion/react */
import { createStateContext } from '@take-two-t2gp/d2c-ui-library';
import { ReportData } from '../types';

type ReportDataType = {
  data?: ReportData;
  loading: boolean;
  error?: string;
};

export const {
  Provider: ReportDataProvider,
  useState: useReportData,
  useSetState: useSetReportData,
} = createStateContext<ReportDataType>({
  loading: false,
});
