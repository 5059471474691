/** @jsxImportSource @emotion/react */
import { css, Global } from '@emotion/react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import 'rsuite/dist/rsuite.min.css';
import tw from 'twin.macro';
import { RVHeader } from './components/RVHeader';
import { ROUTES } from './constant';
import { ReportDataProvider } from './context/ReportDataContext';
import { ReportFetchParamsProvider } from './context/ReportFetchParamsContext';
import { useAppVersion } from './hooks/useAppVersion';
import { Detail } from './pages/Detail';
import { Home } from './pages/Home';

const cssApp = css`
  :root {
    --font-primary: Exo 2, sans-serif;
    --font-secondary: Exo 2, sans-serif;
    --text-color-primary-rgb: 0, 0, 0;
    --text-color-primary: rgb(var(--text-color-primary-rgb));
    --color-white-rgb: 255, 255, 255;
    --color-white: rgb(var(--color-white-rgb));
    --color-black-rgb: 0, 0, 0;
    --color-black: rgb(var(--color-black-rgb));
    --color-primary-rgb: 230, 31, 39;
    --color-primary: rgb(var(--color-primary-rgb));
    --color-success-rgb: 56, 135, 30;
    --color-success: rgb(var(--color-success-rgb));
    --color-error-rgb: 170, 51, 51;
    --color-error: rgb(var(--color-error-rgb));
    --color-warning-rgb: 170, 103, 0;
    --color-warning: rgb(var(--color-warning-rgb));
    --color-info-rgb: 34, 85, 153;
    --color-info: rgb(var(--color-info-rgb));
    --color-neutral-000-rgb: 0, 0, 0;
    --color-neutral-000: rgb(var(--color-neutral-000-rgb));
    --color-neutral-100-rgb: 26, 26, 26;
    --color-neutral-100: rgb(var(--color-neutral-100-rgb));
    --color-neutral-200-rgb: 45, 45, 45;
    --color-neutral-200: rgb(var(--color-neutral-200-rgb));
    --color-neutral-300-rgb: 64, 64, 64;
    --color-neutral-300: rgb(var(--color-neutral-300-rgb));
    --color-neutral-999-rgb: 255, 255, 255;
    --color-neutral-999: rgb(var(--color-neutral-999-rgb));
    --rs-input-bg: #f5f5f5;
    --rs-input-focus-border: rgba(0, 0, 0, 0.2);
    --rs-text-primary: rgb(var(--text-color-primary-rgb));
    --rs-text-secondary: #rgb(var(--text-color-primary-rgb));
  }

  html {
    font-family: 'Exo 2';
  }

  body {
    margin: 0;
  }

  button {
    ${tw`hover:cursor-pointer`}
  }

  [data-reach-menu-popover] {
    ${tw`shadow-near`}
    background: #ebebeb;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 1rem 2rem;
    box-sizing: border-box;
  }

  .rs-picker-toggle-textbox {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .rs-picker-default .rs-picker-toggle.rs-btn-lg {
    padding-top: 13px;
    padding-bottom: 13px;
  }

  .rs-stack {
    flex-direction
  }
  .rs-stack-item {
    ${tw`type-body-m`}
    svg {
      height: 1rem;
      width: 1rem;
      margin-top: 0.5rem;
    }
  }

  .rs-picker-default .rs-picker-toggle.rs-btn-lg {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }


`;

const router = createBrowserRouter([
  {
    path: ROUTES.HOME,
    element: <Home />,
  },
  {
    path: ROUTES.DETAIL,
    element: <Detail />,
  },
]);

function App() {
  const [appVersion] = useAppVersion();
  return (
    <>
      <Global styles={cssApp} />
      <RVHeader version={appVersion} />
      <ReportFetchParamsProvider>
        <ReportDataProvider>
          <RouterProvider router={router} />
        </ReportDataProvider>
      </ReportFetchParamsProvider>
    </>
  );
}

// eslint-disable-next-line import/no-default-export
export default App;
